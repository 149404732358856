<template>
  <div>
    <div class="contain">
      <div class="h1">我的订单</div>
      <div class="h1_line"></div>
      <div class="screen_contain">
        <div
          class="screen_item"
          :class="{ screen_item_active: current == item.id }"
          v-for="(item, index) in screen"
          :key="index"
          @click="changeScreen(item)"
        >
          <div>{{ item.name }}</div>
          <div class="line"></div>
        </div>
      </div>
      <div class="h1_line"></div>
      <el-table
        :data="list"
        style="width: 100%"
        header-cell-class-name="tabe_header"
      >
        <el-table-column label="状态">
          <template slot-scope="scope">
            <div class="status1" v-if="scope.row.status == 1">待支付</div>
            <div class="status2" v-if="scope.row.status == 2">已支付</div>
            <div class="status3" v-if="scope.row.status == 3">已关闭</div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="商品名称" width="240">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.type == 1
                  ? JSON.parse(scope.row.config).title
                  : JSON.parse(scope.row.config).vip_name
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="金额(元)"> </el-table-column>
        <el-table-column prop="create_time" label="下单时间"> </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <div class="btn_icon">
              <img
                src="@/assets/image/icon100.png"
                alt=""
                @click="lookOrderDetail(scope.row)"
                class="del"
              />
              <img
                src="@/assets/image/icon56.png"
                alt=""
                @click="delOrder(scope.row)"
                class="del"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination_contain">
        <div>共{{ total || 0 }}项数据</div>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="page"
          :page-size="size"
          :total="total"
          @current-change="getOrderList"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { postJSON } from "/utils/request";
export default {
  name: "order",
  data() {
    return {
      current: 0,
      screen: [
        { id: 0, name: "全部订单", key: "" },
        { id: 1, name: "待支付", key: "" },
        { id: 2, name: "已支付", key: "" },
        { id: 3, name: "已关闭", key: "" },
      ],
      list: [],
      page: 1,
      size: 20,
      total: 0,
    };
  },
  mounted() {
    this.getOrderList();
  },
  methods: {
    changeScreen(item) {
      this.current = item.id;
      this.page = 1;
      this.list = [];
      this.getOrderList();
    },
    lookOrderDetail(item) {
      let type = item.type; //2vip 1doc
      if (item.status !== 1) {
        //1待支付
        this.$router.push({
          path: "/orderDetail",
          query: {
            order_no: item.order_no,
            type,
          },
        });
      } else {
        // type 判断 是文档还是vip 跳转详情
        this.$router.push({
          name: "payment",
          query: {
            order_no: item.order_no,
          },
        });
      }
    },
    getOrderList(val) {
      this.page = val || 1;
      postJSON("/order/list", {
        page: this.page,
        size: 20,
        status: this.current,
        type: 0,
        // order_type 1文档 2vip 不传全部
      }).then((res) => {
        console.log("res.order", res);
        this.total = res.data.total;
        this.list = res.data.list;
      });
    },
    delOrder(item) {
      postJSON("/order/del", {
        order_no: item.order_no,
      }).then((res) => {
        console.log("删除order返回", res);
        if (res.message == "success") {
          this.$message.success("删除成功");
          this.page = 1;
          this.list = [];
          this.getOrderList();
        } else {
          this.$message.error("删除失败");
        }
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.contain {
  width: 1005px;
  background: #ffffff;
  border-radius: 6px;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  .h1 {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #333333;
    margin-bottom: 20px;
  }
  .h1_line {
    height: 1px;
    background: #f6f7f8;
    margin-bottom: 34px;
  }
  .screen_contain {
    display: flex;
    align-items: center;
    .screen_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 80px;
      cursor: pointer;
      div:nth-child(1) {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #666666;
        margin-bottom: 13px;
      }
      .line {
        width: 72px;
        height: 3px;
        background: transparent;
      }
    }
    .screen_item_active {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 80px;
      cursor: pointer;
      div:nth-child(1) {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #23dc5a;
        margin-bottom: 13px;
      }
      .line {
        width: 72px;
        height: 3px;
        background: #23dc5a;
      }
    }
  }
  ::v-deep .tabe_header {
    background: #f6f7f8 !important;
  }
  .status1 {
    width: 80px;
    height: 39px;
    background: #edfff2;
    border-radius: 6px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: center;
    color: #23dc5a;
    line-height: 39px;
  }
  .status2 {
    width: 80px;
    height: 39px;
    background: #e2e2e2;
    border-radius: 6px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: center;
    color: #999999;
    line-height: 39px;
  }
  .status3 {
    width: 80px;
    height: 39px;
    background: #ffeded;
    border-radius: 6px;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: center;
    color: #dc2323;
    line-height: 39px;
  }
  .pagination_contain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;
    height: 60px;
    background: #f7f7f7;
    border-radius: 2px;
    padding: 0 10px 0 20px;
    box-sizing: border-box;
    ::v-deep .el-pager .active {
      background: #23dc5a !important;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }
    ::v-deep .el-pager .number {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }
    ::v-deep .el-pager .btn-quicknext {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }

    ::v-deep .el-pager .btn-quickprev {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }
    ::v-deep .btn-prev {
      width: 46px;
      height: 46px;
      .el-icon-arrow-left {
        font-size: 24px;
      }
    }
    ::v-deep .btn-next {
      width: 46px;
      height: 46px;
      .el-icon-arrow-right {
        font-size: 24px;
      }
    }
    div:nth-child(1) {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #666666;
      letter-spacing: 0.63px;
    }
  }
}
.btn_icon {
  display: flex;
  align-items: center;
  .del {
    width: 22px;
    height: 22px;
    margin-right: 15px;
    cursor: pointer;
  }
}
</style>