<template>
  <div>
    <div class="box">
      <div class="h1">可用余额</div>
      <div class="h1_line"></div>
      <div class="price">
        <div>￥{{ info.price }}</div>
        <div @click="Withdrawal">提现</div>
      </div>
    </div>
    <div class="contain">
      <div class="h1">收支明细</div>
      <div class="h1_line"></div>
      <el-table
        :data="list"
        style="width: 100%"
        header-cell-class-name="tabe_header"
      >
        <el-table-column label="收支原因" prop="remark"> </el-table-column>
        <el-table-column label="消费类型">
          <template slot-scope="scope">
            <div>{{ scope.row.type == 1 ? "收入" : "支出" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="金额（元）" prop="price">
          <template slot-scope="scope">
            <div>{{ scope.row.price||0 }}</div>
          </template>
           </el-table-column>
        <el-table-column label="时间" prop="create_time"> </el-table-column>
      </el-table>
      <div class="pagination_contain">
        <div>共{{ total || 0 }}项数据</div>
        <el-pagination background layout="prev, pager, next" :current-page="page"
          :page-size="size"
          :total="total"
          @current-change="getList(1)">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { postJSON } from "../../utils/request";
import { get } from "/utils/request";

export default {
  name: "balance",
  data() {
    return {
      list: [
        {
          id: 1,
          desc: "用户下单购买文档（xxx文档）",
          type: "1", //1收入 2支出
          price: 100,
          time: "2023.12.12 12:22",
        },
      ],
      total: 0,
      info: {},
      page: 1,
      size: 20,
    };
  },
  mounted() {
    this.getUserInfo();
    this.getList();
  },
  methods: {
    Withdrawal() {
      this.$emit("backType", true);
    },
    getUserInfo() {
      // /api/v1/user
      get("/info/user", {}).then((res) => {
        console.log("res", res);
        this.info = res.data;
        let info = [
          { name: "文档", num: res.data.doc_count || 0 },
          { name: "收藏", num: res.data.favorite_count || 0 },
          { name: "积分", num: res.data.credit_count || 0 }, //暂无积分字段
          { name: "关注", num: res.data.follow_count || 0 },
          { name: "粉丝", num: res.data.fans_count || 0 },
          { name: "余额", num: res.data.price || 0 }, //暂无余额字段
        ];
        this.count = info;
      });
    },
    getList() {
      postJSON("/getMoneyLog", { page: this.page, size: this.size }).then((res) => {
        console.log(res);
        if(!res.data){
          this.list = [];
          return
        }
        res.data.map((item)=>{
          if (item.create_time) {
            let date = new Date(item.create_time);
            date.setHours(date.getHours());
            item.create_time = date.toLocaleString();
            console.log(date.toLocaleString());
          }
          return item
        })
        this.list = res.data;
        this.total = res.total;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.box {
  margin: 17px 0 20px 0;
  width: 1005px;
  height: 206px;
  background: #ffffff;
  border-radius: 6px;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  .h1 {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #333333;
    margin-bottom: 20px;
  }
  .h1_line {
    height: 1px;
    background: #f6f7f8;
    margin-bottom: 34px;
  }
  .price {
    display: flex;
    align-items: center;
    div:nth-child(1) {
      font-size: 48px;
      font-family: DIN Alternate, DIN Alternate-Bold;
      font-weight: 700;
      color: #333333;
      margin-right: 80px;
    }
    div:nth-child(2) {
      width: 119px;
      height: 44px;
      border: 1px solid #23dc5a;
      border-radius: 6px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: center;
      color: #23dc5a;
      letter-spacing: 0.7px;
      line-height: 44px;
      cursor: pointer;
    }
  }
}
.contain {
  width: 1005px;
  //   height: 934px;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  .h1 {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #333333;
    margin-bottom: 20px;
  }
  .h1_line {
    height: 1px;
    background: #f6f7f8;
    margin-bottom: 20px;
  }
  ::v-deep .tabe_header {
    background: #f6f7f8 !important;
  }
  .touxaing {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  ::v-deep .el-table .el-table__cell.is-right {
    padding-right: 56px;
    box-sizing: border-box;
  }
  .btn {
    width: 76px;
    height: 30px;
    background: #f6f7f8;
    border-radius: 3px;
    font-size: 13px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: center;
    color: #666666;
    line-height: 30px;
    margin: 0 auto;
    cursor: pointer;
  }
  .pagination_contain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;
    height: 60px;
    background: #f7f7f7;
    border-radius: 2px;
    padding: 0 10px 0 20px;
    box-sizing: border-box;
    ::v-deep .el-pager .active {
      background: #23dc5a !important;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }
    ::v-deep .el-pager .number {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }
    ::v-deep .el-pager .btn-quicknext {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }

    ::v-deep .el-pager .btn-quickprev {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }
    ::v-deep .btn-prev {
      width: 46px;
      height: 46px;
      .el-icon-arrow-left {
        font-size: 24px;
      }
    }
    ::v-deep .btn-next {
      width: 46px;
      height: 46px;
      .el-icon-arrow-right {
        font-size: 24px;
      }
    }
    div:nth-child(1) {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #666666;
      letter-spacing: 0.63px;
    }
  }
}
</style>