<template>
  <div>
    <div class="contain">
      <div class="h1">安全设置</div>
      <div class="box1">
        <div class="editTx">
          <input
            type="file"
            style="
              width: 100px;
              height: 100px;
              border-radius: 50%;
              position: absolute;
              left: 0;
              top: 0;
              z-index: 100;
              opacity: 0;
            "
            accept="image/*"
            @change="
              image.file = $event.target.files[0];
              submit();
            "
          />
          <div v-if="info.avatar">
            <img :src="`${$imgURL}${info.avatar}`" alt="" class="img1" />
          </div>
          <img v-else src="@/assets/image/icon53.png" alt="" class="img1" />
        </div>
        <div class="box1_info">
          <div>
            <img src="@/assets/image/icon54.png" alt="" />注册时间：
            {{ info.created_at }}
          </div>
          <div>
            <img src="@/assets/image/icon54.png" alt="" />最后登录：
            {{ info.login_at }}
          </div>
          <!-- <div>
            <img src="@/assets/image/icon55.png" alt="" />登录IP：{{
              info.register_ip
            }}
          </div> -->
        </div>
      </div>
      <div class="screen_contain">
        <div
          class="screen_item"
          :class="{ screen_item_active: current == item.name }"
          v-for="(item, index) in screen"
          :key="index"
          @click="changeScreen(item)"
        >
          <div>{{ item.name }}</div>
          <div class="line"></div>
        </div>
      </div>
      <div class="contain2_line"></div>
      <div v-if="current == '个人资料'">
        <div class="input1">
          <div><span>*</span>&nbsp;<span>账号</span></div>
          <div>
            <el-input
              v-model="info.mobile"
              placeholder=""
              disabled="false"
            ></el-input>
          </div>
        </div>
        <div class="input2">
          <div><span>昵称</span></div>
          <div>
            <el-input
              v-model="info.username"
              placeholder=""
              :disabled="disabled"
            ></el-input>
          </div>
        </div>
        <!-- <div class="input2">
          <div><span>手机号</span></div>
          <div>
            <el-input
              v-model="info.mobile"
              placeholder=""
              :disabled="disabled"
            ></el-input>
          </div>
        </div> -->
        <div class="input2">
          <div><span>所在地</span></div>
          <div>
            <el-input
              v-model="info.address"
              placeholder=""
              :disabled="disabled"
            ></el-input>
          </div>
        </div>
        <div class="input2">
          <div><span>生日</span></div>
          <div>
            <el-input
              v-model="info.birthday"
              placeholder=""
              :disabled="disabled"
            ></el-input>
          </div>
        </div>
        <div class="input2">
          <div><span>微信号</span></div>
          <div>
            <el-input
              v-model="info.wxcode"
              placeholder=""
              :disabled="disabled"
            ></el-input>
          </div>
        </div>
      </div>
      <div v-if="current == '修改资料'">
        <div class="input1">
          <div><span>*</span>&nbsp;<span>账号</span></div>
          <div>
            <el-input
              v-model="info.mobile"
              placeholder=""
              disabled="false"
            ></el-input>
          </div>
        </div>
        <div class="input2">
          <div><span>昵称</span></div>
          <div>
            <el-input
              v-model="info.username"
              placeholder=""
              :disabled="false"
            ></el-input>
          </div>
        </div>
        <!-- <div class="input2">
          <div><span>手机号</span></div>
          <div>
            <el-input
              v-model="info.mobile"
              placeholder=""
              :disabled="disabled"
            ></el-input>
          </div>
        </div> -->
        <div class="input2">
          <div><span>所在地</span></div>
          <div>
            <el-input
              v-model="info.address"
              placeholder=""
              :disabled="disabled"
            ></el-input>
          </div>
        </div>
        <div class="input2">
          <div><span>生日</span></div>
          <div>
            <el-input
              v-model="info.birthday"
              placeholder=""
              :disabled="disabled"
            ></el-input>
          </div>
        </div>
        <div class="input2">
          <div><span>微信号</span></div>
          <div>
            <el-input
              v-model="info.wxcode"
              placeholder=""
              :disabled="disabled"
            ></el-input>
          </div>
        </div>
        <div class="btn1" @click="editUserInfo()">修改资料</div>
      </div>
      <div v-if="current == '修改密码'">
        <div class="input2">
          <div><span>账号</span></div>
          <div>
            <el-input
              v-model="info.mobile"
              placeholder=""
              disabled="false"
            ></el-input>
          </div>
        </div>
        <div class="input1">
          <div><span>*</span>&nbsp;<span>原密码</span></div>
          <div>
            <el-input
              v-model="pwd_obj.old_password"
              placeholder="请输入原密码"
            ></el-input>
          </div>
        </div>
        <div class="input1">
          <div><span>*</span>&nbsp;<span>新密码</span></div>
          <div>
            <el-input
              v-model="pwd_obj.new_password"
              placeholder="请输入新密码"
            ></el-input>
          </div>
        </div>
        <div class="input1">
          <div><span>*</span>&nbsp;<span>确认密码</span></div>
          <div>
            <el-input
              v-model="again_new_password"
              placeholder="请确认密码"
            ></el-input>
          </div>
        </div>
        <div class="btn1" @click="editPwd()">修改密码</div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, postJSON, PUT } from "/utils/request";
export default {
  name: "personalData",
  props: [
    {
      changeUserinfo: {
        type: Function,
        default: () => {},
      },
    },
  ],
  data() {
    return {
      current: "个人资料",
      screen: [
        { id: 1, name: "个人资料" },
        { id: 2, name: "修改资料" },
        { id: 3, name: "修改密码" },
      ],
      image: {
        url: "",
        file: null,
      },
      disabled: true,
      info: {},
      created_at: "",
      login_at: "",
      pwd_obj: {
        old_password: "",
        new_password: "",
      },
      again_new_password: "",
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    submit(e) {
      console.log(e);
      // 上传图片
      console.log(this.image.file);
      const formData = new FormData();
      formData.append("file", this.image.file);
      console.log(formData);
      postJSON("/upload/avatar", formData).then((res) => {
        console.log("头像上传", res);
        if (res.code == 200) {
          // this.image.url = this.imgURL + res.data.path;
          postJSON("/update/user", { avatar: res.data.path }).then((res) => {
            console.log(res);
            if (res.message == "success") {
              this.$message.success("修改成功");
              this.getUserInfo();
              this.changeUserinfo(res.message);
            } else {
              this.$message.success("修改失败" + res.message);
            }
          });
        } else {
          this.$message({
            type: "error",
            title: "头像上传失败",
          });
        }
      });
    },
    changeScreen(item) {
      this.current = item.name;
      if (item.name == "个人资料") {
        this.disabled = true;
      } else if (item.name == "修改资料") {
        this.disabled = false;
      }
    },
    async getUserInfo() {
      // /api/v1/user
      let res = await get("/info/user", {});
      console.log("res", res);
      this.info = res.data;
      window.localStorage.setItem("userInfo", JSON.stringify(res.data));
      // this.$EventBus.$emit("localStorageUpdated");
      console.log(
        "window.localStorage.setIte",
        window.localStorage.getItem("userInfo")
      );

      // console.log(this.$imgURL + res.data.avatar);
      this.created_at = `${res.data.created_at.split("T")[0]} ${
        res.data.created_at.split("T")[1].split("Z")[0]
      }`;
      this.login_at = `${res.data.login_at.split("T")[0]} ${
        res.data.login_at.split("T")[1].split("Z")[0]
      }`;
    },
    editUserInfo() {
      postJSON("/update/user", this.info).then((res) => {
        if (res.message == "success") {
          this.$message.success("修改成功");
          this.getUserInfo();
          this.changeUserinfo(res.message);
          // this.$router.go(0)
        } else {
          this.$message.success("修改失败" + res.message);
        }
      });
    },
    editPwd() {
      // this.pwd_obj.id = this.info.id;
      if (this.pwd_obj.new_password != this.again_new_password) {
        this.$message.error("两次密码不一致");
        return;
      }
      let pwd_obj = this.pwd_obj;
      pwd_obj.id = this.info.id;
      console.log(pwd_obj);
      // console.log(PUT);
      PUT("/user/password", pwd_obj).then((res) => {
        console.log(res);
        if (Object.getOwnPropertyNames(res).length === 0) {
          this.$message.success("修改成功，请重新登录");
          window.localStorage.clear();
          this.$router.replace("/");
          this.$router.go(0);
        } else {
          this.$message.error("修改失败" + res.message);
        }
      });
    },
    changeUserinfo(message) {
      this.$emit("changeUserinfo", message);
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  width: 1005px;
  // height: 1019px;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  .h1 {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #333333;
    margin-bottom: 20px;
  }
  .box1 {
    height: 140px;
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 3px 15px 0px rgba(235, 235, 235, 0.5);
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .editTx {
      position: relative;
      margin-right: 40px;
      cursor: pointer;
      .img1 {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        z-index: 100;
      }
      .img2 {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        position: absolute;
        top: 0%;
        left: 0px;
      }
    }
    .box1_info {
      div {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
        img {
          width: 20px;
          height: 20px;
          margin-right: 3px;
        }
      }
      div:nth-child(2) {
        margin: 12px 0;
      }
    }
  }
  .screen_contain {
    display: flex;
    align-items: center;
    margin-top: 30px;
    .screen_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 80px;
      cursor: pointer;
      div:nth-child(1) {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #666666;
        margin-bottom: 13px;
      }
      .line {
        width: 72px;
        height: 3px;
        background: transparent;
      }
    }
    .screen_item_active {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 80px;
      cursor: pointer;
      div:nth-child(1) {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #23dc5a;
        margin-bottom: 13px;
      }
      .line {
        width: 72px;
        height: 3px;
        background: #23dc5a;
      }
    }
  }
  .contain2_line {
    height: 1px;
    background: #f6f7f8;
    margin-bottom: 20px;
  }
  .input1 {
    margin-bottom: 20px;
    div:nth-child(1) {
      margin-bottom: 15px;
      span:nth-child(1) {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #ff0000;
      }
      span:nth-child(2) {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #333333;
      }
    }
    div:nth-child(2) {
      height: 62px;
      background: #f6f7f8;
      border-radius: 6px;
      ::v-deep .el-input.is-disabled .el-input__inner {
        height: 62px;
        border: none;
      }
      ::v-deep .el-input__inner {
        height: 62px;
        border: none;
        background: #f6f7f8;
      }
    }
  }
  .input2 {
    margin-bottom: 20px;
    div:nth-child(1) {
      margin-bottom: 15px;
      span:nth-child {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #333333;
      }
    }
    div:nth-child(2) {
      height: 62px;
      background: #f6f7f8;
      border-radius: 6px;
      ::v-deep .el-input.is-disabled .el-input__inner {
        height: 62px;
        border: none;
      }
      ::v-deep .el-input__inner {
        height: 62px;
        border: none;
        background: #f6f7f8;
      }
    }
  }
  .btn1 {
    width: 336px;
    height: 45px;
    background: linear-gradient(279deg, #23c48c 15%, #33da61 88%);
    border-radius: 6px;
    font-size: 15px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    line-height: 45px;
    margin: 0 auto;
    cursor: pointer;
  }
}
</style>