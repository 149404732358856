<template>
  <div>
    <div class="contain">
      <div class="h1">请输入提现金额（可提金额：{{ user.price }}元）</div>
      <div class="h1_line"></div>
      <div class="input">
        <span class="h1_span">￥</span>
        <el-input
          placeholder="请输入提现金额"
          v-model="price"
          type="number"
        ></el-input>
      </div>
      <div
        class="h1_line"
        style="margin-bottom: 14px; background: #e5e5e5"
      ></div>
      <div class="h2">提现规则：（满50后方可提现，审核时间三个工作日之内）</div>
      <div class="h1">支付方式</div>
      <div class="h1_line"></div>
      <div class="pay_contain">
        <div v-for="(item, index) in pay" :key="index" class="pay_contain_item">
          <div @click="changePay(item)" class="img1">
            <img
              v-if="currentPay == item.id"
              src="@/assets/image/icon62.png"
              alt=""
              class="img1"
            />
            <img v-else src="@/assets/image/icon63.png" alt="" class="img1" />
          </div>
          <div>{{ item.name }}</div>
          <img :src="item.img" alt="" class="img2" />
        </div>
      </div>
      <div>
        <div class="input2">
          <div><span>平台账号</span></div>
          <div>
            <el-input v-model="info.input1" placeholder="请输入提现账号"></el-input>
          </div>
        </div>
        <div class="input2">
          <div><span>用户姓名</span></div>
          <div>
            <el-input v-model="info.input2" placeholder="请输入姓名"></el-input>
          </div>
        </div>
        <div class="input2">
          <div><span>证件号</span></div>
          <div>
            <el-input v-model="info.input3" placeholder="请输入证件号"></el-input>
          </div>
        </div>
      </div>
      <div class="btn1" @click="tixian">立即提现</div>
    </div>
  </div>
</template>

<script>
import wxImg from "@/assets/image/icon60.png";
import zfbImg from "@/assets/image/icon61.png";
import { get } from "/utils/request";

export default {
  name: "withdrawal",
  data() {
    return {
      price: 0,
      pay: [
        { id: 1, name: "微信支付", img: wxImg },
        { id: 2, name: "支付宝支付", img: zfbImg },
      ],
      currentPay: 1,
      user: {},
      info:{
        input1:'',
        input2:'',
        input3:'',
      }
    };
  },
  mounted () {
    this.getUserInfo();
  },
  methods: {
    changePay(item) {
      this.currentPay = item.id;
      this.info = {
        input1:'',
        input2:'',
        input3:'',
      }
    },
    async getUserInfo() {
      // /api/v1/user
      let res = await get("/info/user", {});
      console.log("res", res);
      this.user = res.data;
    },
    tixian(){
      if(this.price<50){
        this.$message.error('提现金额不能低于50');
        return;
      }
      if(this.info.input1 == ''||this.info.input2 == ''||this.info.input3 == ''){
        this.$message.error('请输入完整信息');
        return;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.contain {
  width: 1005px;
  height: 883px;
  background: #ffffff;
  border-radius: 6px;
  margin: 17px 0 0 0;
  padding: 20px;
  box-sizing: border-box;
  .h1 {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #333333;
    margin-bottom: 20px;
  }
  .h1_line {
    height: 1px;
    background: #f6f7f8;
    margin-bottom: 20px;
  }
  .input {
    margin: 30px 0 0;
    display: flex;
    align-items: center;
    .h1_span {
      font-size: 48px;
      font-family: DIN Alternate, DIN Alternate-Bold;
      color: #333333;
    }
    ::v-deep .el-input__inner {
      height: 56px;
      border: none;
      font-size: 48px;
      font-family: DIN Alternate, DIN Alternate-Bold;
      color: #333333;
      padding-left: 0;
    }
  }
  .h2 {
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #666666;
    letter-spacing: 0.6px;
    margin-bottom: 20px;
  }
  .pay_contain {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .pay_contain_item {
      width: 200px;
      height: 65px;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 6px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #333333;
      letter-spacing: 0.9px;
      .img1 {
        width: 20px;
        height: 20px;
        margin-right: 20px;
        cursor: pointer;
      }
      .img2 {
        width: 25px;
        height: 25px;
        margin-left: 20px;
      }
    }
  }
  .input2 {
    margin-bottom: 20px;
    div:nth-child(1) {
      margin-bottom: 15px;
      span:nth-child {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #333333;
      }
    }
    div:nth-child(2) {
      height: 62px;
      background: #f6f7f8;
      border-radius: 6px;
      ::v-deep .el-input.is-disabled .el-input__inner {
        height: 62px;
        border: none;
      }
      ::v-deep .el-input__inner {
        height: 62px;
        border: none;
        background: #f6f7f8;
      }
    }
  }
  .btn1 {
    width: 336px;
    height: 45px;
    background: linear-gradient(279deg, #23c48c 15%, #33da61 88%);
    border-radius: 6px;
    font-size: 15px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    line-height: 45px;
    margin: 30px auto 0;
  }
}
</style>