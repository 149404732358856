<template>
  <div>
    <div class="contain">
      <div class="h1">粉丝</div>
      <div class="h1_line"></div>
      <el-table
        :data="list"
        style="width: 100%"
        header-cell-class-name="tabe_header"
      >
        <el-table-column label="头像" width="140" align="center">
          <template slot-scope="scope">
            <img
              :src="$imgUrl + scope.row.user.avatar"
              alt=""
              class="touxaing"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="user.username"
          label="昵称"
          width="140"
          align="center"
        >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.user" class="btn" @click="cancel(scope.row)">
                关注
              </div>
              <!-- <div v-if="scope.row.status == 2" class="btn">取消关注</div> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination_contain">
        <div>共{{ total || 0 }}项数据</div>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="page"
          :page-size="size"
          :total="total"
          @current-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { postJSON } from "/utils/request";
// import { get } from "/utils/request";

export default {
  name: "fans",
  data() {
    return {
      list: [],
      total: 0,
      page: 1,
      size: 20,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList(val) {
      if (this.val) {
        this.page = val;
      }
      console.log("page", this.page);
      console.log("size", this.size);
      let obj = {
        page: this.page,
        size: this.size,
      };
      postJSON("/fans/list", obj).then((res) => {
        console.log(res);
        this.total = res.total;
        this.list = res.data;
      });
    },
    cancel(item) {
      console.log(item);
      // 关注
      postJSON("/add/fans", { collect_user_id: item.collect_user_id }).then(
        (res) => {
          console.log(res);
          this.page = 1;
          this.getList();
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
.contain {
  width: 1005px;
  //   height: 934px;
  background: #ffffff;
  border-radius: 6px;
  padding: 20px;
  box-sizing: border-box;
  .h1 {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #333333;
    margin-bottom: 20px;
  }
  .h1_line {
    height: 1px;
    background: #f6f7f8;
    margin-bottom: 20px;
  }
  ::v-deep .tabe_header {
    background: #f6f7f8 !important;
  }
  .touxaing {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  ::v-deep .el-table .el-table__cell.is-right {
    padding-right: 56px;
    box-sizing: border-box;
  }
  .btn {
    width: 76px;
    height: 30px;
    background: #f6f7f8;
    border-radius: 3px;
    font-size: 13px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: center;
    color: #666666;
    line-height: 30px;
    margin: 0 auto;
    cursor: pointer;
  }
  .pagination_contain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;
    height: 60px;
    background: #f7f7f7;
    border-radius: 2px;
    padding: 0 10px 0 20px;
    box-sizing: border-box;
    ::v-deep .el-pager .active {
      background: #23dc5a !important;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }
    ::v-deep .el-pager .number {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }
    ::v-deep .el-pager .btn-quicknext {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }

    ::v-deep .el-pager .btn-quickprev {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }
    ::v-deep .btn-prev {
      width: 46px;
      height: 46px;
      .el-icon-arrow-left {
        font-size: 24px;
      }
    }
    ::v-deep .btn-next {
      width: 46px;
      height: 46px;
      .el-icon-arrow-right {
        font-size: 24px;
      }
    }
    div:nth-child(1) {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #666666;
      letter-spacing: 0.63px;
    }
  }
}
</style>