<template>
  <div>
    <div class="contain">
      <div class="contain_left">
        <div class="contain_left1">
          <div>
            <img
              :src="info.avatar?`${$imgURL}${info.avatar}`:`${touxiang}`"
              alt=""
              class="contain_left1_img1"
            />
          </div>
          <div>
            {{ info.username }}
            <img
              src="@/assets/image/icon43.png"
              alt=""
              class="contain_left1_img2"
              v-if="info.is_vip==1"
            />
          </div>
        </div>
        <div class="contain_left2">
          <div
            v-for="(item, index) in count"
            :key="index"
            @click="jumpCount(item)"
          >
            <div class="contain_left2_item">
              <span>{{ item.name }}</span>
              <span>{{ item.num }}</span>
            </div>
          </div>
        </div>
        <div class="contain_left3" @click="upload_doc">上传文档</div>
        <div
          v-for="(item, index) in tabList"
          :key="index"
          @click="jumpTab(item)"
        >
          <div class="contain_left4">
            <div class="contain_left4_left">
              <img :src="item.img" alt="" /> {{ item.name }}
            </div>
            <img
              src="@/assets/image/icon51.png"
              alt=""
              class="contain_left4_right"
            />
          </div>
          <div
            class="contain_left4_line"
            v-if="tabList.length - 1 != index"
          ></div>
        </div>
      </div>
      <div class="contain_right">
        <personalData
          v-if="type == 1"
          @changeUserinfo="changeUserinfo"
        ></personalData>
        <fans v-if="type == 2"></fans>
        <collect v-if="type == 3"></collect>
        <myFollowing v-if="type == 4"></myFollowing>
        <myVip v-if="type == 5"></myVip>
        <balance v-if="type == 6" @backType="backType"></balance>
        <withdrawal v-if="type == 7"></withdrawal>
        <coupon v-if="type == 8"></coupon>
        <downloads v-if="type == 9"></downloads>
        <order v-if="type == 10"></order>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="show"
      width="30%"
      :before-close="handleClose"
    >
      <span>如需开发票，请联系网站官人员</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="show = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import icon44 from "@/assets/image/icon44.png";
import icon45 from "@/assets/image/icon45.png";
import icon46 from "@/assets/image/icon46.png";
import icon47 from "@/assets/image/icon47.png";
import icon48 from "@/assets/image/icon48.png";
import icon49 from "@/assets/image/icon49.png";
import icon50 from "@/assets/image/icon50.png";
import icon52 from "@/assets/image/icon52.png";
import personalData from "@/components/personalData.vue";
import fans from "@/components/fans.vue";
import collect from "@/components/collect.vue";
import myFollowing from "@/components/myFollowing.vue";
import myVip from "@/components/myVip.vue";
import balance from "@/components/balance.vue";
import withdrawal from "@/components/withdrawal.vue";
import coupon from "@/components/coupon.vue";
import downloads from "@/components/downloads.vue";
import order from "@/components/order.vue";
import { get } from "/utils/request";
export default {
  components: {
    personalData,
    fans,
    collect,
    myFollowing,
    myVip,
    balance,
    withdrawal,
    coupon,
    downloads,
    order,
  },
  data() {
    return {
      type: 1, //1账号设置 2粉丝 3收藏 4关注 5vip 6余额 7提现 8优惠券 9下载 10订单
      count: [],
      tabList: [
        { name: "账号设置", id: 1, img: icon44 },
        { name: "我的VIP", id: 2, img: icon45 },
        { name: "优惠券", id: 3, img: icon46 },
        { name: "我的订单", id: 4, img: icon47 },
        { name: "我的文档", id: 5, img: icon48 },
        { name: "我的下载", id: 6, img: icon49 },
        { name: "发票管理", id: 7, img: icon50 },
      ],
      show: false,
      info: {},
      touxiang:icon52
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    this.getUserInfo();
  },
  // watch: {
  //   $route(to, from) {
  //     console.log("to", to);
  //     console.log("from", from);
  //     this.loadContent(to.query.type)
  //   },
  // },
  beforeRouteUpdate(to, from, next) {
    // 路由参数变化时调用
    console.log("from", from);
    this.type = to.query.type;
    // this.loadContent();
    next();
  },
  methods: {
    loadContent(type) {
      this.type = type
    },
    jumpCount(item) {
      if (item.name == "文档") {
        console.log(item);
        this.$router.push({ path: "/myDocument" });
      }
      if (item.name == "收藏") {
        console.log(item);
        this.type = 3;
      }
      if (item.name == "积分") {
        console.log(item);
        this.$router.push({ path: "/integral" });
      }
      if (item.name == "关注") {
        console.log(item);
        this.type = 4;
      }
      if (item.name == "粉丝") {
        console.log(item);
        this.type = 2;
      }
      if (item.name == "余额") {
        console.log(item);
        this.type = 6;
      }
    },
    jumpTab(item) {
      if (item.name == "账号设置") {
        console.log(item);
        this.type = 1;
      }
      if (item.name == "我的VIP") {
        console.log(item);
        this.type = 5;
      }
      if (item.name == "优惠券") {
        console.log(item);
        this.type = 8;
      }
      if (item.name == "我的订单") {
        console.log(item);
        this.type = 10;
      }
      if (item.name == "我的文档") {
        console.log(item);
        this.$router.push({ path: "/myDocument" });
      }
      if (item.name == "我的下载") {
        console.log(item);
        this.type = 9;
      }
      if (item.name == "发票管理") {
        console.log(item);
        this.show = true;
      }
    },
    backType(item) {
      // 切换提现
      console.log(item);
      if (item) {
        this.type = 7;
      }
    },
    getUserInfo() {
      // /api/v1/user
      get("/info/user", {}).then((res) => {
        console.log("res", res);
        this.info = res.data;
        let info = [
          { name: "文档", num: res.data.doc_count>0?res.data.doc_count: 0 },
          { name: "收藏", num: res.data.favorite_count>0?res.data.favorite_count: 0 },
          { name: "积分", num: res.data.score>0?res.data.score: 0 }, //暂无积分字段
          { name: "关注", num: res.data.follow_count>0?res.data.follow_count:  0 },
          { name: "粉丝", num: res.data.fans_count>0?res.data.fans_count: 0 },
          { name: "余额", num: res.data.price>0?res.data.price: 0 }, //暂无余额字段
        ];
        this.count = info;
      });
    },
    upload_doc() {
      this.$router.push({ path: "/upload" });
    },
    changeUserinfo(message) {
      console.log("-------------message", message);
      if (message == "success") {
        this.getUserInfo();
        this.$router.go(0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  width: 1400px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  .contain_left {
    width: 375px;
    height: 900px;
    background: #ffffff;
    border-radius: 6px;
    padding: 20px;
    box-sizing: border-box;
    .contain_left1 {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .contain_left1_img1 {
        width: 60px;
        height: 60px;
        margin-right: 11px;
      }
      div {
        display: flex;
        flex-direction: column;
        .contain_left1_img2 {
          width: 58px;
          height: 17px;
          margin-top: 8px;
        }
      }
    }
    .contain_left2 {
      width: 100%;
      height: 168px;
      background: url("../../assets/image/icon42.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      //   align-items: center;
      flex-wrap: wrap;
      .contain_left2_item {
        width: 48px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 25px;
        cursor: pointer;
        span:nth-child(1) {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 10px;
        }
        span:nth-child(2) {
          font-size: 20px;
          font-family: DIN Alternate, DIN Alternate-Bold;
          font-weight: 700;
          color: #ffffff;
        }
      }
    }
    .contain_left3 {
      height: 45px;
      background: linear-gradient(279deg, #23c48c 15%, #33da61 88%);
      border-radius: 6px;
      font-size: 15px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
      line-height: 45px;
      margin: 20px 0;
      cursor: pointer;
    }
    .contain_left4 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      .contain_left4_left {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
      .contain_left4_right {
        width: 12px;
        height: 12px;
      }
    }
    .contain_left4_line {
      height: 1px;
      background: #f6f7f8;
      margin: 20px 0;
    }
  }
  .contain_right {
  }
}
</style>